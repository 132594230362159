import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzInput, SzModal } from "react-theme-components";
import { bindActionCreators } from "redux";
import SelectFilter from "../components/Utils/AccmgtTable/Filters/SelectFilter";
import { getFormatedForm, ModifyUser } from "../Models";
import { IInput } from "../Models/ModifyUserForm";
import { Admin as AdminService } from "../services/Admin";
import { AdminActions, ErrorsActions } from "../store/redux/actions";
import { HttpCodes } from "../store/redux/constants";
import "./userForm.scss";

function UserForm(props: any) {
    const { t } = useTranslation();
    const { handleCloseUserForm, user, getUsers, error, resetError, resetErrors,
        modifyUser, errorsApi, addUserGroup, removeUserGroup, addUserAttribute } = props;
    const defaultSelectOption = { label: t("admin:select.choose"), value: 0 };
    const [groupOptions, setGroupOptions] = useState([defaultSelectOption]);
    const [selectedUser, setSelectedUser] = useState(user);
    const [showError, setShowError] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ label: "", value: 0 });
    const [state, setState] = useState({
        userAttributName: "",
        userAttributValue: "",
    });
    const defaultValues: any = {
        email: selectedUser.mail[0],
        firstName: selectedUser.givenName[0],
        lastName: selectedUser.sn[0],
        phone: selectedUser.telephoneNumber ? selectedUser.telephoneNumber[0] : null,
        mobilePhone: selectedUser.mobile ? selectedUser.mobile[0] : null,
        organisation: selectedUser.o ? selectedUser.o[0] : null,
        employeeType: selectedUser.employeeType ? selectedUser.employeeType[0] : null,
    };
    const removeEmpty = (obj: any) => {
        Object.keys(obj).forEach((key) => obj[key] === null && delete obj[key]);
    };
    removeEmpty(defaultValues);

    const [schema, initialValues] = getFormatedForm(ModifyUser, defaultValues);

    useEffect(() => {
        AdminService.getGroupsAsOptions().then((options: Array<{ label: string; value: number }>) => {
            setGroupOptions([defaultSelectOption].concat(options));
        });
    }, []);

    useEffect(() => {
        const updatedUser = props.users.filter((value: any) => selectedUser.mail[0] === value.mail[0]);
        setSelectedUser(updatedUser[0]);
    }, [props.users, selectedUser]);

    const handleClose = () => {
        handleCloseUserForm();
        resetErrors();
    };

    const handleAddGroup = (group: any) => {
        addUserGroup(selectedUser.mail[0], group[0]);
        getUsers();
    };

    const handleRemoveGroup = (group: any) => {
        removeUserGroup(selectedUser.mail[0], group);
        getUsers();
    };

    const handleAddUserAttribute = (name: string, value: string) => {
        addUserAttribute(selectedUser.mail[0], name, value);
        handleClose();
    };

    const changeHandle = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <SzModal className="gaia-user-form" title={t("admin:modifyUser")} show={props.visible}
                 handleClose={handleClose}>
            <Formik
                validationSchema={schema}
                onSubmit={(values: any) => {
                    modifyUser(values).then((response: any) => {
                        if (response && response.status === HttpCodes.HTTP_NO_CONTENT) {
                            handleClose();
                        }
                    });
                }}
                initialValues={initialValues}
                validateOnBlur
                validateOnChange={false}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <form
                        onSubmit={(e: any) => {
                            handleSubmit(e);
                            setShowError(true);
                        }}
                        className="position-relative"
                    >
                        {showError && Object.keys(errors).length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                {errors[Object.keys(errors)[0]]}
                            </div>
                        )}
                        {Object.keys(errorsApi.messages).length > 0 &&
                        Object.values(errorsApi.messages).map((error: any) => (
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        ))}
                        {Object.keys(ModifyUser).map((name: any) => {
                            const propModifyUser: keyof typeof ModifyUser = name;
                            const val: IInput = ModifyUser[propModifyUser];
                            const input = val as IInput;

                            return (
                                selectedUser.hasOwnProperty(input.field) && <SzInput
                                    className={`gaia-inscription-form__form-group d-block d-md-inline-block ${input.className}`}
                                    key={name}
                                    label={input.label}
                                    name={name}
                                    icon={input.icon}
                                    required={input.required}
                                    disabled
                                    valid={!errors[name] && touched[name]}
                                    error={touched[name] && errors[name]}
                                    value={values[name]}
                                    onChange={handleChange}
                                    onBlur={(e: any) => {
                                        handleBlur(e);
                                        if (error) {
                                            resetError();
                                        }
                                        if (showError) {
                                            setShowError(false);
                                        }
                                    }}
                                    placeholder={input.placeholder}
                                    type={input.type}
                                />
                            );
                        })}
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("admin:user.email")}</label>
                            <div className="col-sm-9">
                                <input type="text" readOnly className="form-control-plaintext"
                                       value={selectedUser.mail[0]}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("admin:user.login")}</label>
                            <div className="col-sm-9">
                                <input type="text" readOnly className="form-control-plaintext"
                                       value={selectedUser.mail[0]}/>
                            </div>
                        </div>
                        {selectedUser.entryUUID && <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("admin:user.entryUUID")}</label>
                            <div className="col-sm-9">
                                <input type="text" readOnly className="form-control-plaintext"
                                       value={selectedUser.entryUUID[0]}/>
                            </div>
                        </div>}
                        {selectedUser.entryDN && <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("admin:user.entryDN")}</label>
                            <div className="col-sm-9">
                                <input type="text" readOnly className="form-control-plaintext"
                                       value={selectedUser.entryDN[0]}/>
                            </div>
                        </div>}

                        <div className="form-group row">
                            <div className="col">
                                <SzInput
                                    name="userAttributName"
                                    value={state.userAttributName}
                                    onChange={changeHandle}
                                    label={t("admin:addAttributeName")}
                                    placeholder={t("admin:addAttributeName")}
                                />
                            </div>
                            <div className="col">
                                <SzInput
                                    name="userAttributValue"
                                    value={state.userAttributValue}
                                    onChange={changeHandle}
                                    label={t("admin:addAttributeValue")}
                                    placeholder={t("admin:addAttributeValue")}
                                />
                            </div>
                            <div className="col pt-4">
                                <SzButton
                                    onClick={
                                        () => handleAddUserAttribute(state.userAttributName, state.userAttributValue)
                                    }>
                                    {t("admin:addAttribute")}
                                </SzButton>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("admin:user.groups")}</label>
                            <div className="col-sm-9">
                                {selectedUser.userGroups && Object.keys(selectedUser.userGroups).length > 0 &&
                                <ul className="group-list">
                                    {Object.values(selectedUser.userGroups).map((group: any, key: any) => (
                                        <li key={key}>
                                            {group.dn}
                                            <span onClick={() => handleRemoveGroup(group.gidNumber)}>
                                              <Icon className={"clickable icon-close pull-right"} path={mdiClose}
                                                    size={1}/>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-8">
                                <SelectFilter
                                    options={groupOptions}
                                    handler={setSelectedOption}
                                    label=""
                                    value={selectedOption}
                                />
                            </div>
                            <div className="col-sm-4">
                                <SzButton onClick={() => handleAddGroup(selectedOption.value)}>
                                    {t("admin:addGroup")}
                                </SzButton>
                            </div>
                        </div>
                        <div className="gaia-inscription-form__validate mt-1 m-auto">
                            <div className="row justify-content-center">
                                <SzButton className="col-9 col-md-3 m-0" type="submit"
                                          isDisabled={!schema.isValidSync(values)}>
                                    {t("admin:save")}
                                </SzButton>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </SzModal>
    );
}

const mapStateToProps = (state: any) => {
    return {
        errorsApi: state.error.form,
        groups: state.admin.groups.objects,
        users: state.admin.users.objects,
    };
};
const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators({ ...AdminActions, ...ErrorsActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
