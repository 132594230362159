import axios from "axios";
import { config } from "../config";
import { store } from "../store";
import { ERROR } from "../store/redux/constants";
import { Authenticate as AuthenticateService } from "./Authenticate";
import { Token as TokenService } from "./Token";

class Request {
  public static cancel: any;
  private base: string;
  private CancelToken: any;

  constructor() {
    this.base = config.apiUrl as string;
    this.CancelToken = axios.CancelToken;
  }

  public async get(endpoint: string, cancelOption = false) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.sendRequest(endpoint, cancelOption));
    } else {
      return this.sendRequest(endpoint, cancelOption);
    }
  }

  public async post(endpoint: string, body: any, cancelOption = false) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.postRequest(endpoint, body, cancelOption));
    } else {
      return this.postRequest(endpoint, body, cancelOption);
    }
  }

  public async put(endpoint: string, body: any, cancelOption = false) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.putRequest(endpoint, body, cancelOption));
    } else {
      return this.putRequest(endpoint, body, cancelOption);
    }
  }

  public async delete(endpoint: string) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.deleteRequest(endpoint));
    } else {
      return this.deleteRequest(endpoint);
    }
  }

  public async patch(endpoint: string, body: any) {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.patchRequest(endpoint, body));
    } else {
      return this.patchRequest(endpoint, body);
    }
  }

  public async refreshToken() {
    const body = JSON.stringify({
      refresh_token: sessionStorage.getItem("refreshToken"),
    });
    const endpoint = `/token/refresh`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, body);
  }

  public cancelRequest() {
    Request.cancel && Request.cancel();
  }

  public async checkJWT() {
    const endpoint = `/token/check`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.get(endpoint);
  }

  private sendRequest(endpoint: string, cancelOption = false) {
    this.cancelRequest();
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c;
        }),
      };
    }
    const httpInstance = this.getHttpInstance();

    // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    //   return httpInstance.get("/search/filters");
    // } else {
    return httpInstance.get(endpoint, param);
    // }
  }

  private postRequest(endpoint: string, body: any, cancelOption = false) {
    this.cancelRequest();
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c;
        }),
      };
    }
    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, JSON.stringify(body), param);
  }

  private putRequest(endpoint: string, body: any, cancelOption = false) {
    this.cancelRequest();
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c;
        }),
      };
    }
    const httpInstance = this.getHttpInstance();
    return httpInstance.put(endpoint, JSON.stringify(body), param);
  }

  private patchRequest(endpoint: string, body: any) {
    const httpInstance = this.getHttpInstance();
    return httpInstance.patch(endpoint, JSON.stringify(body));
  }

  private deleteRequest(endpoint: string) {
    const httpInstance = this.getHttpInstance();
    return httpInstance.delete(endpoint);
  }

  private getHttpInstance() {
    const { base } = this;
    const httpInstance = axios.create({
      baseURL: `${base}`,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "authorization": `Bearer ${sessionStorage.getItem("token")}`,
      },
    });

    httpInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const {
            status,
            data: { message },
          } = error.response;
          const isJwtError = 401 === status;
          store.dispatch({
            payload: { status, message },
            type: ERROR.SET,
          });
          if (isJwtError) {
            AuthenticateService.logout();
          }
        } else {
          store.dispatch({
            payload: { status: 0, message: error.message },
            type: ERROR.SET,
          });
        }

        return Promise.reject(error);
      },
    );
    return httpInstance;
  }
}

export default new Request();
