import i18next from "i18next";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SzTable from "react-theme-components/build/components/SzTable/SzTable";
import { bindActionCreators } from "redux";
import { AppActions } from "../../../store/redux/actions";
import "./AccmgtTable.scss";
import Filters, { IFilters } from "./Filters";
import Pagination from "./Pagination/Pagination";

interface IAccmgtTable {
  className?: string;
  data: {
    objects: object[];
    totalItem: number;
    totalPage: number;
    currentPage: number;
  };
  header: object[];
  keyField: string;
  sort?: Array<{ order: string; dataField: string }>;
  updateHandler: (keywords: string, newPage: number, selectOption: number) => void;
  resetHandler?: () => void;
  filters?: IFilters;
  launchLoading: () => void;
  stopLoading: () => void;
}

export const defaultSelectOption = { label: i18next.t("admin:select.all") as string || "-- Tous --", value: 0 };

const AccmgtTable = (props: IAccmgtTable): ReactElement => {
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState(props.data.objects);
  const [searchKeyword, setSearchKeyword]: [string, (s: string) => void] = useState("");
  const [selectedOption, setSelectedOption] = useState(defaultSelectOption);
  const [currentPage, setCurrentPage]: [number, (n: number) => void] = useState(props.data.currentPage);

  const usePrevious = <T extends {}>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevValue = usePrevious({searchKeyword, selectedOption, currentPage});

  useEffect(() => {
    setFilteredData(props.data.objects);
    props.stopLoading();
  }, [props.data.objects]);

  useEffect(() => {
    if ("undefined" !== typeof props.updateHandler) {
      if (prevValue && (prevValue.searchKeyword !== searchKeyword || prevValue.selectedOption !== selectedOption)) {
        setCurrentPage(1);
      }
      setFilteredData([]);
      props.launchLoading();
      props.updateHandler(searchKeyword, currentPage, selectedOption.value);
    }
  }, [searchKeyword, selectedOption, currentPage]);

  if (props.data.objects.length > 0) {
    return (
      <div className={`accmgt_table ${props.className ? props.className : ""}`}>
        {props.filters && (
          <Filters
            filters={props.filters}
            data={filteredData}
            inputHandler={setSearchKeyword}
            selectHandler={setSelectedOption}
            resetHandler={props.resetHandler}
          />
        )}

        <div className={"accmgt_table--element_count"}>
          <span className={"accmgt_table--element_count--number"}> {props.data.totalItem} </span>
          <span className={"accmgt_table--element_count--label"}> {t("admin:totalResults")} </span>
        </div>

        <SzTable columns={props.header} data={filteredData} defaultSorted={props.sort} keyField={props.keyField} />
        <Pagination
          totalPage={props.data.totalPage as number}
          currentPage={props.data.currentPage as number}
          handler={setCurrentPage}
        />
      </div>
    );
  }
  return (
    <div className="accmgt_table m-auto justify-content-center align-items-center">
      {props.filters && (
        <Filters
          filters={props.filters}
          data={filteredData}
          inputHandler={setSearchKeyword}
          selectHandler={setSelectedOption}
          resetHandler={props.resetHandler}
        />
      )}
      <div className="text-center">
        <Table striped bordered hover responsive className="sz-table">
          <thead>
            <tr className="bg-light-blue">
              {props.header.map((header: any, key: any) => (
                <th key={key}>{header.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={props.header.length as number}>{t("no-data")}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(AppActions, dispatch),
});

export default connect(null, mapDispatchToProps)(AccmgtTable);
