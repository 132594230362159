import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "react-theme-components/assets/css/main.css";
import AppRouter from "./App";
import "./i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { history } from "./store";

ReactDOM.render(
    <Suspense fallback="loading...">
        <AppRouter history={history} />
    </Suspense>,
    document.getElementById("accout-manager-app"),
);
serviceWorker.unregister();
