import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzInput, SzModal } from "react-theme-components";
import { bindActionCreators } from "redux";
import { getFormatedForm, ModifyGroup } from "../Models";
import { IInput } from "../Models/ModifyGroupForm";
import { AdminActions, ErrorsActions } from "../store/redux/actions";
import { HttpCodes } from "../store/redux/constants";
import "./groupForm.scss";

function GroupForm(props: any) {
  const { t } = useTranslation();
  const { handleCloseUserForm, group } = props;
  const [selectedGroup, setSelectedGroup] = useState(group);
  const [showError, setShowError] = useState(false);
  const { error, resetError, resetErrors, modifyGroup, errorsApi } = props;
  const defaultValues: any = {
    dn: selectedGroup.dn,
    description: selectedGroup.description[0],
    gidNumber: selectedGroup.gidNumber[0],
  };
  const [schema, initialValues] = getFormatedForm(ModifyGroup, defaultValues);

  useEffect(() => {
    const updatedGroup = props.groups.filter((value: any) => selectedGroup.gidNumber[0] === value.gidNumber[0]);
    setSelectedGroup(updatedGroup[0]);
  }, [props.groups]);

  const handleClose = () => {
    handleCloseUserForm();
    resetErrors();
  };

  return (
    <SzModal className="gaia-group-form" title={t("admin:modifyGroup")} show={props.visible} handleClose={handleClose}>
      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          modifyGroup(values).then((response: any) => {
            if (response && response.status === HttpCodes.HTTP_NO_CONTENT) {
              handleClose();
            }
          });
        }}
        initialValues={initialValues}
        validateOnBlur
        validateOnChange={false}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <form
            onSubmit={(e: any) => {
              handleSubmit(e);
              console.log(e);
              setShowError(true);
            }}
            className="position-relative"
          >
            {showError && Object.keys(errors).length > 0 && (
              <div className="alert alert-danger" role="alert">
                {errors[Object.keys(errors)[0]]}
              </div>
            )}
            {Object.keys(errorsApi.messages).length > 0 &&
              Object.values(errorsApi.messages).map((error: any) => (
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              ))}
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">{t("admin:group.gidNumber")}</label>
              <div className="col-sm-9">
                <input type="text" readOnly className="form-control-plaintext" value={selectedGroup.gidNumber[0]} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">{t("admin:group.dn")}</label>
              <div className="col-sm-9">
                <input type="text" readOnly className="form-control-plaintext" value={selectedGroup.dn} />
              </div>
            </div>
            {Object.keys(ModifyGroup).map((name: any) => {
              const propModifyGroup: keyof typeof ModifyGroup = name;
              const val: IInput = ModifyGroup[propModifyGroup];
              const input = val as IInput;

              return (
                <SzInput
                  className={`gaia-group-update-form__form-group d-block d-md-inline-block ${input.className}`}
                  key={name}
                  label={input.label}
                  name={name}
                  icon={input.icon}
                  required
                  disabled
                  valid={!errors[name] && touched[name]}
                  error={touched[name] && errors[name]}
                  value={values[name]}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    if (error) {
                      resetError();
                    }
                    if (showError) {
                      setShowError(false);
                    }
                  }}
                  placeholder={input.placeholder}
                  type={input.type}
                />
              );
            })}
            {
              (selectedGroup.member && selectedGroup.member.length > 0) &&
              <div className="form-group">
                <label className="col-form-label">{t("admin:group.members")}</label>
                <ul className="group-list mr-3">
                  {
                    selectedGroup.member.map((user: string) =>
                        <li>{user}</li>,
                    )
                  }
                </ul>
              </div>
            }
            <div className="gaia-inscription-form__validate mt-1 m-auto">
              <div className="row justify-content-center">
                <SzButton className="col-9 col-md-3 m-0" type="submit" isDisabled={!schema.isValidSync(values)}>
                  {t("admin:save")}
                </SzButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </SzModal>
  );
}

const mapStateToProps = (state: any) => {
  return {
    errorsApi: state.error.form,
    groups: state.admin.groups.objects,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...AdminActions, ...ErrorsActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);
