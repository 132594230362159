import * as Yup from "yup";

export enum IFormModifyUser {
    firstName,
    lastName,
}

export interface IInput {
    className?: string;
    icon?: string;
    label: string;
    disabled?: string;
    placeholder?: string;
    schema?: Yup.StringSchema<any>;
    type: string;
    field: string;
    required: boolean;
}

export default {
    firstName: {
        className: "pr-md-3",
        label: "Prénom",
        placeholder: "Saisir votre prénom",
        schema: Yup.string()
            .min(2, "Le champ prénom doit être rempli.")
            .required("Le champ prénom doit être rempli."),
        type: "text",
        field: "givenName",
        required: true,
    },
    lastName: {
        className: "pl-md-3",
        label: "Nom",
        placeholder: "Saisir votre nom",
        schema: Yup.string()
            .min(2, "Le champ nom doit être rempli.")
            .max(50, "Too Long!")
            .required("Le champ nom doit être rempli."),
        type: "text",
        field: "sn",
        required: true,
    },
    phone: {
        className: "pr-md-3",
        label: "Téléphone",
        placeholder: "Saisir votre téléphone",
        schema: Yup.string()
            .max(50, "Nombre maximum de caractères atteint!"),
        type: "text",
        field: "telephoneNumber",
        required: false,
    },
    mobilePhone: {
        className: "pl-md-3",
        label: "Mobile",
        placeholder: "Saisir votre mobile",
        schema: Yup.string()
            .max(50, "Nombre maximum de caractères atteint!"),
        type: "text",
        field: "mobile",
        required: false,
    },
    organisation: {
        className: "pr-md-3",
        label: "Organisation",
        placeholder: "Saisir votre organisation",
        schema: Yup.string()
            .max(50, "Nombre maximum de caractères atteint!"),
        type: "text",
        field: "o",
        required: false,
    },
    employeeType: {
        className: "pr-md-3",
        label: "Type d'employé",
        placeholder: "Saisir le type d'employé",
        schema: Yup.string()
            .max(50, "Nombre maximum de caractères atteint!"),
        type: "text",
        field: "employeeType",
        required: false,
    },
};
