import { push } from "connected-react-router";
import { API_ROUTES, LOCAL_ROUTES } from "../../../config/routes";
import { Authenticate as AuthenticateService } from "../../../services/Authenticate";
import Request from "../../../services/Request";
import { Constants} from "../constants";
import { AppActions, IUser } from "../types";
import AppReducer from "./app";

export default {
    login(user: IUser): AppActions {
        return {
            payload: { ...user },
            type: Constants.LOG_IN,
        };
    },
    logout(user?: IUser): any {
        AuthenticateService.logout();
        return {
            payload: { ...user },
            type: Constants.LOG_OUT,
        };
    },
    getUserGroups(): any {
        return (dispatch: any) => {
            dispatch(AppReducer.launchLoading());
            Request.get(API_ROUTES.USER_GROUP)
                .then((res: any) => {
                    dispatch({
                        payload: {
                            groups: res.data,
                        },
                        type: Constants.RETRIEVE_USER_GROUPS,
                    });
                })
                .catch((error: any) => {
                    const { status } = error.response;
                    if (403 === status) {
                        dispatch(push(LOCAL_ROUTES.DENIED));
                    }
                })
                .finally(() => {
                    dispatch(AppReducer.stopLoading());
                });
        };
    },
};
