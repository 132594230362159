import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import Container from "./components/Container";
import Profil from "./components/Profil/Profil";
import PrivateRoute from "./components/Utils/PrivateRoute/PrivateRoute";
import { LOCAL_ROUTES } from "./config/routes";
import Dashboard from "./pages/Dashboard";
import Denied from "./pages/Errors/denied";
import Groups from "./pages/Groups";
import Logout from "./pages/Logout";
import Requests from "./pages/Requests";
import Signup from "./pages/Signup";
import Users from "./pages/Users";
import Thanks from "./pages/Utils/thanks";
import { Authenticate as AuthenticateService } from "./services/Authenticate";
import { store } from "./store";

interface IAppProps {
    history: History;
}

const AppRouter = ({ history }: IAppProps) => {
    useEffect(() => {
        AuthenticateService.intialize();
    }, []);

    return (
        <>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Container>
                        <Switch>
                            {/*<PrivateRoute exact={true} path={LOCAL_ROUTES.DASHBOARD} component={Dashboard} />*/}
                            <Route exact={true} path={LOCAL_ROUTES.PROFILE} component={Profil} />
                            <Route exact={true} path={LOCAL_ROUTES.SIGN_UP} component={Signup}/>
                            <Route exact={true} path={LOCAL_ROUTES.LOGOUT} component={Logout}/>
                            <Route exact={true} path={LOCAL_ROUTES.THANKS} component={Thanks}/>
                            <Route exact={true} path={LOCAL_ROUTES.DENIED} component={Denied}/>
                            <PrivateRoute exact={true} path={LOCAL_ROUTES.REQUESTS} component={Requests}/>
                            <PrivateRoute exact={true} path={LOCAL_ROUTES.USERS} component={Users} />
                            <PrivateRoute exact={true} path={LOCAL_ROUTES.GROUPS} component={Groups} />
                        </Switch>
                    </Container>
                </ConnectedRouter>
            </Provider>
        </>
    );
};

export default AppRouter;
