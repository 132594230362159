import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RequestsTable from "../../components/RequestsTable";
import { LOCAL_ROUTES } from "../../config/routes";

function Requests(props: { connected: boolean, history: History }) {
    const { t } = useTranslation();

    return (<>
        {props.connected && <div>
            <div className="mt-3 offset-9 col-3">
                <Link
                    className="font-family-bold text-uppercase position-relative overflow-hidden sz-btn border-0 py-3 btn btn-secondary"
                    to={LOCAL_ROUTES.SIGN_UP}>
                    {t("newRequest")}
                </Link>
            </div>
            <RequestsTable/>
        </div>}
    </>);
}

const mapStateToProps = (state: any) => {
    return {
        connected: state.user.logged,
        history: state.history,
    };
};

export default connect(mapStateToProps)(Requests);
