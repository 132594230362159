import { store } from "../..";
import { API_ROUTES } from "../../../config/routes";
import { Admin as AdminService } from "../../../services/Admin";
import Request from "../../../services/Request";
import { Constants, ERROR } from "../constants";
import { IGroup, IRequest, IUser } from "../types";
import AppReducer from "./app";

const getRequests = (keywords?: string, currentPage?: number, selectOption?: number) => {
  return (dispatch: any) => {
    AdminService.getRequestListPromise(keywords, 20, currentPage, selectOption)
      .then((res: {data: IRequest[], headers: any}) => {
        dispatch({
          payload: res,
          type: Constants.RETRIEVE_REQUESTS,
        });
      });
  };
};

const updateStatus = (id: string, validate: boolean) => {
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    Request.patch(`${API_ROUTES.REQUEST}${id}`, { requestStatus: validate })
      .then((response: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch(getRequests());
      })
      .catch((error: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch({
          type: ERROR.FORM.SET,
          payload: { form: { messages: error.response.data } },
        });
      });
  };
};

const getGroups = (keywords?: string, currentPage?: number, selectOption?: number) => {
  keywords = keywords ? keywords : "";
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    AdminService.getGroupsListPromise(keywords, 20, currentPage, selectOption)
      .then((res: {data: IGroup[], headers: any}) => {
        dispatch({
          payload: res,
          type: Constants.RETRIEVE_GROUPS,
        });
      });
  };
};

const getUsers = (keywords?: string, currentPage?: number, selectOption?: number) => {
  const rootState = store.getState();
  keywords = keywords ? keywords : rootState.admin.users.keywords;
  selectOption = selectOption ? selectOption : rootState.admin.users.group;
  currentPage = currentPage ? currentPage : rootState.admin.users.currentPage;
  return (dispatch: any) => {
    AdminService.getUsersListPromise(keywords, selectOption, 20, currentPage)
      .then((res: {data: IUser[], headers: any, keywords?: string, group?: number}) => {
        dispatch({
          payload: res,
          type: Constants.RETRIEVE_USERS,
        });
      });
  };
};

const resetUsersFilters = () => {
  return {
    type: Constants.RESET_USERS_FILTER,
  };
};

const modifyUser = (form: any, action: any): any => {
  const email = form.email;
  const body = { ...form };
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    return Request.put(`${API_ROUTES.USER}${email}`, body)
      .then((response: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch(getUsers());
        return response;
      })
      .catch((error: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch({
          type: ERROR.FORM.SET,
          payload: { form: { messages: error.response.data } },
        });
        return error;
      });
  };
};

const deleteUser = (user: any): any => {
  const email = user.mail ? user.mail[0] : "";
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    return Request.delete(`${API_ROUTES.USER}${email}`)
        .then((response: any) => {
          dispatch(AppReducer.stopLoading());
          dispatch(getUsers());
          return response;
        })
        .catch((error: any) => {
          dispatch(AppReducer.stopLoading());
          dispatch({
            type: ERROR.FORM.SET,
            payload: { form: { messages: error.response.data } },
          });
          return error;
        });
  };
};

const addUserAttribute = (email: string, name: string, value: string) => {
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    const data: any = {
      name,
      value,
    };
    Request.post(`${API_ROUTES.USER}${email}/attribute/add`, data)
        .then((response: any) => {
          dispatch(getUsers());
        })
        .catch((error: any) => {
          dispatch({
            type: ERROR.FORM.SET,
            payload: { form: { messages: error.response.data } },
          });
        })
        .finally(() => {
          dispatch(AppReducer.stopLoading());
        });
  };
};

const removeUserGroup = (email: string, group: string) => {
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    Request.patch(`${API_ROUTES.USER}${email}/group/remove`, { group })
      .then((response: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch(getUsers());
      })
      .catch((error: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch({
          type: ERROR.FORM.SET,
          payload: { form: { messages: error.response.data } },
        });
      });
  };
};

const addUserGroup = (email: string, group: string) => {
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    Request.patch(`${API_ROUTES.USER}${email}/group/add`, { group })
      .then((response: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch(getUsers());
      })
      .catch((error: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch({
          type: ERROR.FORM.SET,
          payload: { form: { messages: error.response.data } },
        });
      });
  };
};

const modifyGroup = (form: any, action: any): any => {
  const gidNumber = form.gidNumber;
  const body = { ...form };
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    return Request.patch(`${API_ROUTES.GROUP}${gidNumber}`, body)
      .then((response: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch(getGroups());
        return response;
      })
      .catch((error: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch({
          type: ERROR.FORM.SET,
          payload: { form: { messages: error.response.data } },
        });
        return error;
      });
  };
};

const changeUserStatus = (email: string, status: boolean) => {
  return (dispatch: any) => {
    dispatch(AppReducer.launchLoading());
    Request.patch(`${API_ROUTES.USER}${email}`, { userStatus: status })
      .then((response: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch(getUsers());
      })
      .catch((error: any) => {
        dispatch(AppReducer.stopLoading());
        dispatch({
          type: ERROR.FORM.SET,
          payload: { form: { messages: error.response.data } },
        });
      });
  };
};

export default {
  changeUserStatus,
  modifyUser,
  modifyGroup,
  getRequests,
  updateStatus,
  getGroups,
  getUsers,
  removeUserGroup,
  addUserGroup,
  resetUsersFilters,
  addUserAttribute,
  deleteUser,
};
